<template>
  <v-container fluid>
    <div class="app">
      <v-row>
        <v-col cols="12">
          <BackButton :handler="backToReports" />
        </v-col>
      </v-row>

      <div class="user-wrapper mt-4">
        <div
          class="md-layout-item md-medium-size-100 md-xsmall-size-100 md-size-50"
        >
          <v-row>
            <v-col cols="12">
              <div class="" style="background-color: rgba(17, 42, 70, 0.04)">
                <p class="pa-4 font-extrabold text-xl text-blue">Filters</p>
              </div>
            </v-col>
          </v-row>

          <v-row class="">
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.productTypeId"
                outlined
                placeholder="Product Type"
                item-value="id"
                item-text="name"
                return-object
                :items="productTypes"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>
            <v-col cols="2">
              <v-autocomplete
                v-model="searchParams.venueServiceId"
                outlined
                placeholder="Services"
                item-value="id"
                item-text="name"
                return-object
                :items="venueServices"
                class="q-autocomplete shadow-0"
                hide-details
                dense
              >
              </v-autocomplete>
            </v-col>

            <v-col cols="2" style="padding-right: 0">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    outlined
                    v-model="date1Formatted"
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.from_date"
                  @input="menu1 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="date2Formatted"
                    outlined
                    readonly
                    v-on="on"
                    class="q-text-field shadow-0"
                    hide-details
                    dense
                    style="background-color: #ffffff"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="searchParams.to_date"
                  @input="menu2 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="2">
              <v-flex xs1 class="ml-1">
                <v-btn color="#00b0af" height="40" dark @click="getReport"
                  >Go</v-btn
                >
              </v-flex>
            </v-col>
          </v-row>

          <div class="md-card md-theme-default shadow-0 rounded-5">
            <div class="search_customer">
              <div class="header_title pl-4 pr-4">
                <v-row>
                  <v-col md="4">Cashier Audit Report </v-col>
                  <v-col md="4"> </v-col>
                  <v-col md="4" class="text-right">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-if="
                            checkExportPermission(
                              $modules.reports.cashier_audit.slug
                            )
                          "
                          class="export-button mt-2"
                          elevation="0"
                          height="40"
                          v-bind="attrs"
                          v-on="on"
                        >
                          <SvgIcon text="Export Report">
                            <template v-slot:icon>
                              <ExportIcon />
                            </template>
                          </SvgIcon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="reportDownload('pdf')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-pdf-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as PDF</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="reportDownload('excel')">
                          <v-list-item-icon>
                            <v-icon>mdi-file-excel-box</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title>Export as Excel</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-col>
                </v-row>
              </div>
            </div>

            <div class="md-card-content">
              <div>
                <div
                  class="md-content md-table md-theme-default table_borders"
                  table-header-color="orange"
                  value
                >
                  <div
                    class="md-content md-table-content md-scrollbar md-theme-default"
                  >
                    <table class="logTable">
                      <thead class="md-card-header shadow-0">
                        <tr>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Timestamp</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Receipt Number
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Cashier Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Customer Name
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Order Date</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Service
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label cursor-pointer">
                                Products
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Price</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Tax</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Grand Total</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Cash</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Visa</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Master</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Amex
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      color="primary"
                                      dark
                                      v-bind="attrs"
                                      v-on="on"
                                    >
                                      mdi-information
                                    </v-icon>
                                  </template>
                                  <span>American Express</span>
                                </v-tooltip>
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Other (card)
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Wallet</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">
                                Credit Facility
                              </div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Cheque</div>
                            </div>
                          </th>
                          <th class="md-table-head">
                            <div
                              class="md-table-head-container md-ripple md-disabled"
                            >
                              <div class="md-table-head-label">Voucher</div>
                            </div>
                          </th>
                        </tr>
                      </thead>

                      <tbody
                        v-if="cashierAuditReport.length"
                        :set="((totalPrice = 0), (totalTax = 0), (total = 0))"
                      >
                        <tr
                          class="md-table-row"
                          :key="`${lndex}`"
                          v-for="(data, lndex) in cashierAuditReport"
                        >
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.created_at | timeStamp }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.order_seq_no != null
                                  ? data.order_seq_no
                                  : "--"
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center font-weight-black"
                            >
                              {{
                                data.cashier_name != null
                                  ? data.cashier_name
                                  : "NA"
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.customer_name ? data.customer_name : "" }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.order_date | dateformat }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{
                                data.service_name != null
                                  ? data.service_name
                                  : "--"
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              {{ data.products != null ? data.products : "--" }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(totalPrice += data.price)"
                          >
                            <div class="md-table-cell-container text-center">
                              {{
                                data.price != null ? data.price : 0 | toCurrency
                              }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(totalTax += data.tax)"
                          >
                            <div class="md-table-cell-container text-center">
                              {{ data.tax != null ? data.tax : 0 | toCurrency }}
                            </div>
                          </td>
                          <td
                            class="md-table-cell"
                            :set="(total += data.total)"
                          >
                            <div class="md-table-cell-container text-center">
                              {{
                                data.total != null ? data.total : 0 | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center cash"
                            >
                              {{
                                getPaymentByType(1, data.payments) | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center card-visa"
                            >
                              {{
                                getPaymentByType(2, data.payments, 6)
                                  | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center card-master"
                            >
                              {{
                                getPaymentByType(2, data.payments, 7)
                                  | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center card-amex"
                            >
                              {{
                                getPaymentByType(2, data.payments, 8)
                                  | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center card-other"
                            >
                              {{
                                getPaymentByType(2, data.payments, 9)
                                  | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center wallet"
                            >
                              {{
                                getPaymentByType(6, data.payments) | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center credit-facility"
                            >
                              {{
                                getPaymentByType(5, data.payments) | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center cheque"
                            >
                              {{
                                getPaymentByType(7, data.payments) | toCurrency
                              }}
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div
                              class="md-table-cell-container text-center voucher"
                            >
                              {{
                                getPaymentByType(3, data.payments) | toCurrency
                              }}
                            </div>
                          </td>
                        </tr>
                        <tr class="md-table-row grand_total_bg sticky_bottom">
                          <td class="md-table-cell" colspan="7">
                            <div class="md-table-cell-container text-center">
                              <strong>GRAND TOTAL</strong>
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{
                                  "AED " + parseFloat(totalPrice).toFixed(2)
                                }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{
                                  "AED " + parseFloat(totalTax).toFixed(2)
                                }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell">
                            <div class="md-table-cell-container text-center">
                              <strong>
                                {{
                                  "AED " + parseFloat(total).toFixed(2)
                                }}</strong
                              >
                            </div>
                          </td>
                          <td class="md-table-cell" colspan="10"></td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr>
                          <td :colspan="7">
                            <v-card-text class="pa-8 text-center">
                              <h3>No data found!</h3>
                            </v-card-text>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <v-pagination
                  class="mt-3 new-pagination"
                  v-model="page"
                  total-visible="7"
                  :length="totalPages"
                ></v-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import BackButton from "@/components/Common/BackButton.vue";
import SvgIcon from "@/components/Image/SvgIcon.vue";
import ExportIcon from "@/assets/images/misc/export-icon.svg";
export default {
  components: { ExportIcon, SvgIcon, BackButton },
  data() {
    return {
      cashierAuditReport: [],
      services: [],
      menu1: false,
      menu2: false,
      fileName: null,
      searchParams: {
        venueServiceId: null,
        productTypeId: null,
        from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
        to_date: moment().format("YYYY-MM-DD"),
      },
      totalPages: 0,
      page: 1,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler() {
        this.getReport();
      },
    },
  },
  computed: {
    date1Formatted() {
      return moment(this.searchParams.from_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    date2Formatted() {
      return moment(this.searchParams.to_date, "YYYY-MM-DD").format(
        "Do MMM YYYY"
      );
    },
    dateLength() {
      return this.cashierAuditReport.length;
    },
    rowSpan() {
      return parseInt(this.cashierAuditReport.length / 4);
    },
    venueServices() {
      return this.$store.getters.getVenueServices.data;
    },
    productTypes() {
      return this.$store.getters.getProductTypes.data;
    },
  },
  mounted() {
    if (this.$store.getters.getVenueServices.status == false) {
      this.$store.dispatch("loadVenueServices");
    }
    if (this.$store.getters.getProductTypes.status == false) {
      this.$store.dispatch("loadProductTypes");
    }
  },
  methods: {
    toggle(type, field) {
      this.$nextTick(() => {
        if (this.searchParams[field].length == this[type].length) {
          this.searchParams[field] = [];
        } else {
          this.searchParams[field] = this[type].map((item) => item.id);
        }
      });
      this.$forceUpdate();
    },
    getIcon(type, field) {
      let icon = "mdi-checkbox-blank-outline";

      if (this.searchParams[field].length == this[type].length) {
        icon = "mdi-close-box";
      }
      if (
        this.searchParams[field].length > 0 &&
        this.searchParams[field].length != this[type].length
      )
        icon = "mdi-minus-box";

      return icon;
    },
    backToReports() {
      this.$router.push({
        name: "Reports",
      });
    },
    getReport() {
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Loading");
      this.$http
        .get(`venues/reports/cashier-audit-report${url}&page=${this.page}`)
        .then((response) => {
          if (response.status == 200) {
            const data = response.data;
            this.cashierAuditReport = data.data;
            this.totalPages = data.total_pages;
            this.hideLoader();
            this.$forceUpdate();
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    reportDownload(type) {
      let link = "";
      if (type == "pdf") {
        link = "cashier-audit-report-pdf";
      } else if (type == "excel") {
        link = "cashier-audit-report-excel";
      }
      let url = this.getFilterUrlData();
      if (!url) return;
      this.showLoader("Downloading report");
      this.$http
        .get(`venues/reports/` + link + `${url}`, {
          responseType: "blob",
        })
        .then((response) => {
          this.hideLoader();
          if (response.status == 200) {
            this.downloadReport(response, this.fileName, type);
          }
        })
        .catch((error) => {
          this.errorChecker(error);
        });
    },
    getFilterUrlData() {
      if (
        moment(this.searchParams.from_date, "YYYY-MM-DD").isAfter(
          moment(this.searchParams.to_date, "YYYY-MM-DD")
        )
      ) {
        this.showError("To date must be greater than or equal to from date");
        return;
      }
      let url = `?from_date=${this.searchParams.from_date}&to_date=${this.searchParams.to_date}`;
      this.fileName =
        this.searchParams.from_date + "-to-" + this.searchParams.to_date;
      let venueService = null;
      let productType = null;
      if (this.searchParams.venueServiceId) {
        url += `&venue_service_id=${this.searchParams.venueServiceId.venue_service_id}`;
        venueService = this.findValueByKey(
          this.venueServices,
          this.searchParams.venueServiceId.venue_service_id
        );
      }

      if (this.searchParams.productTypeId) {
        url += "&product_type_id=" + this.searchParams.productTypeId.id;
        productType = this.findValueByKey(
          this.productTypes,
          this.searchParams.productTypeId.id
        );
      }

      if (productType !== null)
        this.fileName += "-" + productType.name.replace(/\s/g, "");
      if (venueService !== null)
        this.fileName += "-" + venueService.name.replace(/\s/g, "");
      if (this.fileName != null)
        this.fileName = "Cashier-Audit-Report-" + this.fileName;
      else this.fileName = "Cashier-Audit-Report";
      return url;
    },
    findValueByKey(opts, key) {
      var match = opts.filter(function (opt) {
        return opt.id === key;
      });
      return match[0] ? match[0] : null;
    },
    getPaymentByType(payment_method_id = 1, payments, card_type_id = null) {
      let totalAmount = 0;
      totalAmount = payments.reduce((acc, payment) => {
        if (card_type_id) {
          if (payment.card_type_id === card_type_id) {
            acc += payment.total;
          }
        } else {
          if (payment.payment_method_id === payment_method_id) {
            acc += payment.total;
          }
        }
        return acc;
      }, 0);
      return totalAmount;
    },
  },
};
</script>

<style scoped>
.logTable tbody tr:hover {
  transform: scale(1) !important;
  -webkit-transform: scale(1) !important;
  -moz-transform: scale(1) !important;
  box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -webkit-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  -moz-box-shadow: 0px 0px 5px 2px rgba(13, 84, 139, 0.3) !important;
  z-index: 1;
}

.md-card-header {
  background-color: #edf7f8; /* Set color opacity to 10% */
}
</style>
